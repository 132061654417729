<template>
  <div>
    <div class="card">
      <!-- <div class="card-header"><h5 class="card-title">Orders</h5></div> -->
      <div class="card-body row">

        <div class="col-sm-12">
          <div class="pull-left">
            <h5 class="card-title">Orders</h5>
          </div>
          <div class="pull-right" v-if="listMode">
            <el-input prefix-icon="el-icon-search" v-model="textSearch" size="small" placeholder="Shop Name"
              @input="loadOrders"
            />
          </div>
          <div class="pull-right" v-else>
            <p-button type="info" round @click="copyOrder()"> Copy Order </p-button>
          </div>
        </div>

        <div class="col-sm-12" v-if="listMode">
          <el-table :data="orders" header-row-class-name="text-primary"
            @sort-change="sortChange"
          >
            <el-table-column prop="id" label="ID" width="60" sortable="custom">
              <template slot-scope="props">
                <span v-if="props.row.isRead==1">{{ props.row.id }}</span>
                <span class="blink" v-else>{{ props.row.id }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="entryDate" label="Date" width="100" sortable="custom"></el-table-column>
            <el-table-column prop="shopname" label="Shop Name" sortable="custom"></el-table-column>
            <el-table-column prop="lotname" label="Lot Name"></el-table-column>
            <el-table-column label="Total">
              <template slot-scope="props">
                <table>
                  <tr><td>Total:</td><td style="text-align:right;">{{props.row.totalPrice}}</td></tr>
                  <tr><td>Deposit:</td><td style="text-align:right;">{{props.row.deposit}}</td></tr>
                  <tr><td>Paid:</td><td style="text-align:right;">{{props.row.paid}}</td></tr>
                  <tr><td>Balance:</td><td style="text-align:right;">{{props.row.balance}}</td></tr>
                </table>
              </template>
            </el-table-column>
            <!-- <el-table-column prop="totalPrice" label="Total" total="100" sortable="custom" align="right"></el-table-column>
            <el-table-column prop="deposit" label="Deposit" total="100" sortable="custom" align="right"></el-table-column>
            <el-table-column prop="paid" label="Paid" total="100" sortable="custom" align="right"></el-table-column>
            <el-table-column prop="balance" label="Balance" total="100" sortable="custom" align="right"></el-table-column> -->

            <el-table-column prop="status" label="Shipment" sortable="custom"></el-table-column>
            <el-table-column class-name="action-buttons td-actions" min-width="90" align="right" label="Actions" total="100">
              <template slot="header" slot-scope="scope">
                <p-button type="info" round @click="newOrder()"> add new </p-button>
              </template>
              <template slot-scope="props">
                <p-button type="success" size="sm" icon @click="editOrder(props.$index, props.row)">
                  <i class="fa fa-edit"></i>
                </p-button>
                <p-button type="danger" size="sm" icon @click="confirmToDelete(props.$index, props.row)">
                  <i class="fa fa-times"></i>
                </p-button>
                <p-button type="success" size="sm" icon @click="printOrderDetail(props.row)">
                  <i class="nc-icon nc-paper"></i>
                </p-button>                
              </template>
            </el-table-column>
          </el-table>
          <br />
          <div class="block">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page.sync="pagination.currentPage"
              :page-sizes="pagination.pageSizes"
              :page-size="pagination.pageSize"
              :total="pagination.total"
              layout="total, sizes, prev, pager, next, jumper"
            >
            </el-pagination>
          </div>
        </div>

        <div class="col-sm-12" v-else>

          <!-- Customer -->
          <div class="extended-forms">
            <div class="card">
              <div class="card-body">

                <div class="row">                 
                  <div class="col-md-4">
                    <h5 class="card-title">Shop Name</h5>
                    <!-- <fg-input placeholder="" v-model="orderDetail.userID"></fg-input> -->
                    <el-autocomplete class="inline-input" placeholder="" v-model="orderDetail.shopname"
                      :fetch-suggestions="searchCustomer" @select="selectCustomer"
                    >
                    </el-autocomplete>
                  </div>
                  <div class="col-md-6">
                    <h5 class="card-title">Lot Name</h5>
                    <fg-input placeholder="" v-model="orderDetail.lotname"></fg-input>
                  </div>
                  <!-- <div class="col-md-8">
                    <h5 class="card-title">ที่อยู่</h5>
                    <fg-input placeholder="" :disabled="true" v-model="orderDetail.address"></fg-input>
                  </div> -->
                </div>
                <br/>

                <!-- <div class="row">
                  <div class="col-md-12">
                    <h5 class="card-title">หมายเหตุ</h5>
                    <fg-input placeholder="" v-model="orderDetail.remark"></fg-input>
                  </div>
                </div>
                <br/> -->

              </div>
            </div> <!-- end card -->
          </div>

          <!-- Items -->
          <div v-if="showOrderItem">
            <div class="col-sm-12">
              <el-table class="table-shopping" :summary-method="getSummaries" show-summary style="width: 100%" :data="orderItems">
                <el-table-column width="120">
                  <template slot-scope="props"><img :src="props.row.imageUrl" alt="" ></template>
                </el-table-column>
                <el-table-column min-width="150">
                  <template slot-scope="{row}">
                    <a>{{row.name}}</a>
                    <br>
                    <small>{{row.subTitle}}</small>
                  </template>
                </el-table-column>
                <el-table-column width="140" class="td-price" label="Price">
                  <template slot-scope="props">
                    <small>SRP:</small> {{props.row.srp}}<br />
                    <small>WSP:</small> {{props.row.wsp}}<br />
                    <small>Deposit:</small> {{props.row.deposit}}<br />
                  </template>
                </el-table-column>
                <el-table-column min-width="220" label="Start / Ends / Delivery">
                  <template slot-scope="props">
                    Order Start: {{props.row.startDate}}<br />
                    Order Ends: {{props.row.endDate}}<br />
                    Appox. Delivery: {{props.row.deliveryDate}}<br />

                    <el-button type="text" @click="editProductDate(props.$index, props.row)" >
                      <!-- <i class="el-icon-stopwatch"></i> -->
                      <i class="el-icon-edit"></i>
                      Edit Date
                    </el-button>
                  </template>
                </el-table-column>
                <el-table-column width="100" label="QTY" class="td-number td-quantity">
                  <template slot-scope="props">
                    {{props.row.quantity}}
                    <div class="btn-group btn-group-sm">
                      <p-button type="default" round outline size="sm" @click="removeQty(props.$index, props.row)">
                        <i class="nc-icon nc-simple-delete"></i>
                      </p-button>
                      <p-button type="default" round outline size="sm" @click="addQty(props.$index, props.row)">
                        <i class="nc-icon nc-simple-add"></i>
                      </p-button>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="Total" width="90" align="right">
                  <template slot-scope="props">
                    <strong><small>&#3647;</small> {{props.row.quantity * props.row.price}} </strong>
                  </template>
                </el-table-column>
                <el-table-column align="center" label="Actions">
                  <template slot-scope="props">
                    <p-button type="danger" size="sm" icon @click="deleteOrderItem(props.$index, props.row)">
                      <i class="fa fa-times"></i>
                    </p-button>      
                  </template>
                </el-table-column>
              </el-table>
            </div>

            <div class="col-md-12">
              <div class="col-sm-4">
                <h5 class="card-title">Memo</h5>
              </div>
              <div class="col-sm-12">
                <fg-input placeholder="" v-model="orderDetail.memo" ></fg-input>
              </div>
              
            </div>

            <div class="row">
              <div class="col-sm-6">
                <p-button type="info" round 
                  @click.native="showSelectProduct = true; showOrderItem= false; loadProducts()"
                >
                  + Add Item
                </p-button>
              </div>
              <div class="col-md-6">
                <p-button type="info" round @click="addOrder()" v-if="isNewOrder"> New Order </p-button>
                <p-button type="info" round @click="updateOrder()" v-else> Update </p-button>
                &nbsp;
                <p-button type="danger" round @click="listMode=true, loadOrders()"> cancel </p-button>
              </div>
            </div>
            <br />

            <div class="row">
              <div class="col-sm-3">
                <h5 class="card-title">ชำระแล้ว (บาท)</h5>
                <div class="col-sm-10">
                  <fg-input placeholder="0.00" type="number" v-model="orderDetail.paid" ></fg-input>
                </div>
              </div>
              <div class="col-sm-3">
                <h5 class="card-title">Balance (บาท)</h5>
                <div class="col-sm-10">
                  <fg-input placeholder="0.00" type="number" :value="this.total-orderDetail.paid" :disabled="true"></fg-input>
                </div>
              </div>

              <div class="col-sm-3">
                <h5 class="card-title">ธนาคารที่โอนเงิน</h5>
                <el-select class="select-success" size="large" placeholder="" v-model="orderDetail.tranferToBankAccount" >
                  <el-option v-for="option in bankAccount" 
                    :value="option.id"
                    :label="option.bankName+' - '+option.accountNumber"
                    :key="option.label"
                  >
                  </el-option>
                </el-select>
              </div>

              <div class="col-sm-3">
                <h5 class="card-title">สถานะ</h5>
                <el-select class="select-success" size="large" placeholder="" v-model="orderDetail.status">
                  <el-option v-for="option in selectStatus" 
                    :value="option.value" :label="option.label" :key="option.label"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>

            <br />

            <!-- payin slip upload -->
            <div class="row">
              <div class="col-md-2" v-for="(item, index) in slips">
                <img :src="item.imageUrl" class="btnZoomIn" @click="slipZoomIn(item)" />
                {{ item.entryDate }}
              </div>

              <!-- <div class="col-sm-4">
                <h5 class="card-title">ธนาคารที่โอนเงิน</h5>
                <el-select class="select-success" size="large" placeholder="" v-model="orderDetail.tranferToBankAccount" >
                  <el-option v-for="option in bankAccount" 
                    :value="option.id"
                    :label="option.bankName+' - '+option.accountNumber"
                    :key="option.label"
                  >
                  </el-option>
                </el-select>
              </div> -->

            </div>
            <div class="row" v-if="slips.length>0">
              <div class="col-md-4">เอกสารการโอนเงิน</div>
            </div>
            <div v-else>
              ยังไม่มีเอกสารการโอนเงิน
            </div>

            <div class="row">
              <div class="col-md-5"></div>
              <div class="col-md-6">
                <p-button type="info" round @click="updatePayinInformation()" v-if="isNewOrder==false"> Update การชำระเงิน</p-button>
              </div>
            </div>

          </div>

          <!-- Select Product -->
          <div v-if="showSelectProduct">
            <div class="table-responsive">
              <el-table class="table-shopping" style="width:100%" :data="products">
                <el-table-column width="150">
                  <template slot-scope="props">
                    <div class="img-container" v-if="props.row.imageUrl">
                      <img :src="props.row.imageUrl" :alt="props.row.name">
                    </div>
                  </template>
                </el-table-column>
                <el-table-column class-name="td-name">
                  <template slot-scope="{row}">
                    <a>{{row.name}}</a><br>
                    <small>
                      ราคาขาย {{row.srp}} บาท<br />
                      ราคาส่ง {{row.wsp}} บาท<br />
                      มัดจำ {{row.deposit}} บาท
                    </small>
                  </template>
                </el-table-column>
                <el-table-column class="td-name">
                  <template slot-scope="{row}">
                    <br>
                    <small>
                      เริ่มขายวันที่ {{row.startDate}} <br />
                      สิ้นสุดขายวันที่ {{row.endDate}} <br />
                      ส่งสินค้าภายในวันที่ {{row.deliveryDate}} <br />
                    </small>
                  </template>
                </el-table-column>
                <el-table-column >
                  <template slot-scope="props">
                    <p-button type="info" round @click="addCart(props.row)">
                      เลือกสินค้า
                    </p-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>

            <div class="block">
            <el-pagination
              @size-change="handleSizeChangeProduct"
              @current-change="handleCurrentChangeProduct"
              :current-page.sync="paginationProduct.currentPage"
              :page-sizes="paginationProduct.pageSizes"
              :page-size="paginationProduct.pageSize"
              :total="paginationProduct.total"
              layout="total, sizes, prev, pager, next, jumper"
            >
            </el-pagination>
          </div>

            <div class="row">
              <div class="col-md-5"></div>
              <div class="col-md-6">
                <p-button type="danger" round @click="showSelectProduct = false;showOrderItem= true;">
                  Close
                </p-button>
              </div>
            </div>
          </div>

        </div>

        <!-- Dialog Item Date -->
        <el-dialog title="Edit Date" :visible.sync="dialogFormVisible">
          <el-form :model="form">
            <div class="row">
              <div class="col-md-6">
                Order Start: 
                <el-date-picker v-model="newDate.startDate" type="datetime"
                  format="yyyy-MM-dd HH:mm:ss"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  placeholder="Select date and time">
                </el-date-picker>
                
              </div>
              <div class="col-md-6">
                Order Ends: 
                <el-date-picker v-model="newDate.endDate" type="datetime"
                  format="yyyy-MM-dd HH:mm:ss"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  placeholder="Select date and time">
                </el-date-picker>
              </div>
            </div>
          </el-form>
          <span slot="footer" class="dialog-footer">
            <el-button @click="dialogFormVisible = false">Cancel</el-button>
            <el-button type="primary" @click="saveProductDate">Update</el-button>
          </span>
        </el-dialog>

      </div>
    </div>
  </div>
</template>

<script>
  import Vue from 'vue'
  import { Table, TableColumn, Form } from 'element-ui'
  import { DatePicker, TimeSelect, Input, Button, Select, Option, Autocomplete, Pagination, MessageBox, Dialog } from 'element-ui'
  import { Modal } from 'src/components/UIComponents'
  import NotificationTemplate from 'src/components/Dashboard/Views/Components/NotificationTemplate'
  import axios from 'axios'
  import moment from 'moment'

  Vue.use(Table)
  Vue.use(TableColumn)
  export default{
    components: {
      [DatePicker.name]: DatePicker,
      [TimeSelect.name]: TimeSelect,
      [Input.name]: Input,
      [Button.name]: Button,
      [Option.name]: Option,
      [Select.name]: Select,
      [Autocomplete.name]: Autocomplete,
      [Pagination.name]: Pagination,
      [Dialog.name]: Dialog,
      [Form.name]: Form,
      
      Modal,
    },
    data () {
      return {
        listMode: true,
        orders: [],
        textSearch: '',
        pagination: {
          pageSizes: [10, 20, 50],
          pageSize: 10,
          total: 0,
          currentPage: 1,
          sortBy: 'id',
          orderBy: 'desc',
        },
        paginationProduct: {
          pageSizes: [10, 20, 50],
          pageSize: 10,
          total: 0,
          currentPage: 1,
          sortBy: 'id',
          orderBy: 'desc',
        },
        orderItems: [],
        products: [],
        showOrderItem: true,
        showSelectProduct: false,
        isNewOrder: false,
        cartItems: [],
        selectStatus : [
          { value:'Confirmed', label:'Confirmed' },
          { value:'Modified', label:'Modified' },
          { value:'Canceled', label:'Canceled' },
          { value:'Partially Shipped', label:'Partially Shipped' },
          { value:'Completed', label:'Completed' },
        ],
        disabledShopname: false,
        orderDetail : {
            orderID : 0,
            userID : 0,
            shopname : '',
            lotname : '',
            remark: '',
            memo: '',
            paid: 0,
            status: 'Confirmed',
        },
        links: [],
        slips: [],
        apiUrl: process.env.NODE_ENV === 'production'?'https://www.hbrxws.com/api':'http://localhost/hbrxws/api',
        bankAccount: [],
        total: 0,
        totalDeposit: 0,
        dialogFormVisible: false,
        form: {
          name: '',
          region: '',
          date1: '',
          date2: '',
          delivery: false,
          type: [],
          resource: '',
          desc: ''
        },
        newDate : {
          productID: 0,
          startDate: '',
          endDate: ''
        },
        curentOrderRow : [],
      }
    },
    mounted() {
      const token = localStorage.getItem('authToken');

      axios.post(
        this.apiUrl+'/user?action=getUserDetail', { token:token },
        { headers: { 'Content-Type': 'multipart/form-data' } }
      )
      .then(response => {
        if(response.data.role!='admin'){
          this.$router.push('/login');
        }
      });

      this.loadOrders();
      this.loadBankAccount();
    },
    methods: {
      formatDate(value) {
        // Implement custom formatting logic here
        return moment(value).format('YYYY-MM-DD HH:mm:ss');
      },
      addQty(index, row){
        this.cartItems[index]['quantity']++;
        this.cartItems[index]['qty'] = this.cartItems[index]['quantity'];
        this.orderItems = this.cartItems
      },
      removeQty(index, row){
        this.cartItems[index]['quantity']--;
        this.cartItems[index]['qty'] = this.cartItems[index]['quantity'];
        this.orderItems = this.cartItems
      },
      getSummaries (param) {      
        const { columns } = param
        const sums = []

        columns.forEach((column, index) => {
          if (index === 1) {
            sums[index] = 'Total'
          } else if (index === 2) {

          } else if (index < columns.length - 1) {
            sums[index] = ''
          } else {
            let sum = 0
            let sumDeposit = 0

            this.orderItems.forEach((obj) => {
              sumDeposit += parseFloat(obj.quantity) * obj.deposit
              sum += parseFloat(obj.quantity) * obj.wsp
            })

            sums[2] = `Deposit: ฿ ${sumDeposit}`
            sums[5] = `฿ ${sum}`

            this.totalDeposit = sumDeposit;
            this.total = sum;
          }
        })
        return sums
      },
      handleClose (tag) {
        this.tags.dynamicTags.splice(this.tags.dynamicTags.indexOf(tag), 1)
      },
      handleInputConfirm () {
        let inputValue = this.tags.inputValue
        if (inputValue) {
          this.tags.dynamicTags.push(inputValue)
        }
        this.tags.inputVisible = false
        this.tags.inputValue = ''
      },
      confirmToDelete (index, row) {
        MessageBox.confirm('This will permanently delete. Continue?', 'Warning', {
          confirmButtonText: 'OK', cancelButtonText: 'Cancel', type: 'warning'
        }).then(() => {
          this.deleteItem (index, row);
        }).catch(() => {
          
        });
      },
      async loadOrders () {
        axios.post(
          this.apiUrl+'/orders',
          { 
            'pageSize': this.pagination.pageSize,
            'currentPage': this.pagination.currentPage,
            'sortBy': this.pagination.sortBy,
            'orderBy': this.pagination.orderBy,
            'q': this.textSearch,
          },
          {
            headers: { 'Content-Type': 'multipart/form-data' }
          }
        )
        .then(response => {
          this.orders = response.data.rows;
          this.pagination.total = parseInt(response.data.total);
        })
        .catch(error => {
          this.error = error;
        });

      },
      async loadOrderDetail (row) {
        this.isNewOrder = false;

        this.orderDetail = row
        this.orderDetail.orderID = row.id;

        axios.post(
          this.apiUrl+'/orders?action=orderDetail',
          { 'id': row.id },
          { headers: { 'Content-Type': 'multipart/form-data' } }
        )
        .then(response => {
          this.state1 = row.fullname

          this.cartItems = response.data.rows;
          this.orderItems = this.cartItems

          this.loadSlip(row.id);
        })
        .catch(error => {
          this.error = error;
        });
      },
      async newOrder (){
        this.listMode = false
        this.isNewOrder = true;
        this.disabledShopname = false;

        this.orderDetail.shopname = '';
        this.orderDetail.lotname = '';
        this.orderDetail.paid = '';
        this.orderDetail.tranferToBankAccount = '';
        this.orderDetail.status = '';

        this.cartItems = []
        this.orderItems = this.cartItems

        // this.loadSlip(0);
        this.slips = []
      },
      async loadProducts () {
        axios.post(
          this.apiUrl+'/products?action=listActiveProduct',
          { 
            'pageSize': this.paginationProduct.pageSize,
            'currentPage': this.paginationProduct.currentPage,
            'sortBy': this.paginationProduct.sortBy,
            'orderBy': this.paginationProduct.orderBy,
          },
          {
            headers: { 'Content-Type': 'multipart/form-data' }
          }
        )
        .then(response => {
          this.products = response.data.rows;
          this.paginationProduct.total = parseInt(response.data.total);
        })
        .catch(error => {
          this.error = error;
        });

      },
      async addCart (row){
        this.showSelectProduct = false;
        this.showOrderItem= true;

        await axios.post(
          this.apiUrl+'/orders?action=addCart',
          { 'id': row.id },
          {
            headers: { 'Content-Type': 'multipart/form-data' }
          }
        )
        .then(response => {
          const item = response.data
          item.price = parseFloat(item.wsp)
          
          this.cartItems.push(item);
          this.orderItems = this.cartItems;
        })
        .catch(error => {
          this.error = error;
        });
      },
      async addOrder(){
        if(this.orderDetail.userID==0){
          this.$notify({
            component: NotificationTemplate,
            icon: 'nc-icon nc-app',
            horizontalAlign: 'center',
            verticalAlign: 'bottom',
            type: 'danger',
            message: 'ไม่ได้เลือกร้าน !'
          })
          return false;
        }

        await axios.post(
          this.apiUrl+'/orders?action=addOrder', 
          {
            'customer': this.orderDetail,
            'items': this.cartItems,
          },
          {
            headers: { 'Content-Type': 'multipart/form-data' }
          }
        )
        .then(response => {

          if(response.data.success){
            this.cartItems = []
            this.orderItems = this.cartItems

            this.loadOrders();
            this.listMode = true;

            this.$notify({
              component: NotificationTemplate,
              icon: 'nc-icon nc-app',
              horizontalAlign: 'center',
              verticalAlign: 'bottom',
              type: 'success',
              message: 'เพิ่ม Order เรียบร้อยแล้ว'
            })

          }else{
            this.$notify({
              component: NotificationTemplate,
              icon: 'nc-icon nc-app',
              horizontalAlign: 'center',
              verticalAlign: 'bottom',
              type: 'danger',
              message: response.data.errorMsg
            })
          }

        })
        .catch(error => {
          this.$notify({
              component: NotificationTemplate,
              icon: 'nc-icon nc-app',
              horizontalAlign: 'center',
              verticalAlign: 'bottom',
              type: 'danger',
              message: 'API Error !'
            })
        });
        
      },
      async editOrder (index, row) {
        this.listMode = false;
        this.disabledShopname = true;

        this.loadOrderDetail(row);

        this.curentOrderRow = row;

      },
      async updateOrder () {
        axios.post(
          this.apiUrl+'/orders?action=updateOrder',          
          {
            'customer': this.orderDetail,
            'items': this.cartItems,
          },
          { headers: { 'Content-Type': 'multipart/form-data' } }
        )
        .then(response => {
          if(response.data.success){
            this.loadOrders();

            this.$notify({
              component: NotificationTemplate,
              icon: 'nc-icon nc-app',
              horizontalAlign: 'center',
              verticalAlign: 'bottom',
              type: 'success',
              message: 'บันทึก Order เรียบร้อยแล้ว'
            })
          }else{
            this.$notify({
              component: NotificationTemplate,
              icon: 'nc-icon nc-app',
              horizontalAlign: 'center',
              verticalAlign: 'bottom',
              type: 'danger',
              message: response.data.errorMsg
            })
          }
        })
        .catch(error => {
          this.error = error;
        });

        this.listMode = true;
      },
      async deleteItem (index, row) {
        try {
          // Make a POST request using Axios
          const response = await axios.post(
            this.apiUrl+'/orders?action=deleteOrder', row,
            {
              headers: { 'Content-Type': 'multipart/form-data' }
            }
          );

          this.loadOrders();

          this.$notify({
            component: NotificationTemplate,
            icon: 'nc-icon nc-app',
            horizontalAlign: 'center',
            verticalAlign: 'bottom',
            type: 'success',
            message: 'ลบรายการเรียบร้อยแล้ว'
          })
        } catch (error) {
          this.$notify({
            component: NotificationTemplate,
            icon: 'nc-icon nc-app',
            horizontalAlign: 'center',
            verticalAlign: 'bottom',
            type: 'danger',
            message: error
          })
        }
      },
      async searchCustomer(queryString, cb) {
        await axios.post(
          this.apiUrl+'/userAccount',
          { q: queryString },
          { headers: { 'Content-Type': 'multipart/form-data' } }
        )
        .then(response => {
          cb(response.data.rows);
        })
        .catch(error => {
          this.error = error;
        });
      },
      async selectCustomer(item) {
        this.orderDetail.userID = item.id;
        // this.orderDetail.address = item.address;
      },
      handleSizeChange(val) {
        this.pagination.pageSize = val;
        this.loadOrders();
      },
      handleCurrentChange(val) {
        this.pagination.currentPage = val;
        this.loadOrders();
      },
      handleSizeChangeProduct(val) {
        this.paginationProduct.pageSize = val;
        this.loadProducts();
      },
      handleCurrentChangeProduct(val) {
        this.paginationProduct.currentPage = val;
        this.loadProducts();
      },
      printOrderDetail (row) {
        window.open(this.apiUrl+'/orders?action=printOrderDetail&id='+row.id,'_new')
      },
      loadSlip(docID){
        axios.post(
          this.apiUrl+'/orders?action=loadSlips', { 'orderID':docID },
          {
            headers: { 'Content-Type': 'multipart/form-data' }
          }
        )
        .then(response => {
          this.slips = response.data.rows;
        })
        .catch(error => {
          this.error = error;
        });
      },
      sortChange (param) {
        this.pagination.sortBy = param.prop;
        this.pagination.orderBy = param.order;
        this.loadOrders();
      },
      async loadBankAccount () {
        axios.get(this.apiUrl+'/bankAccount')
        .then(response => {
          this.bankAccount = response.data.rows;
        })
        .catch(error => {
          this.error = error;
        });
      },
      deleteOrderItem(index, row){
        MessageBox.confirm('This will permanently delete. Continue?', 'Warning', {
          confirmButtonText: 'OK', cancelButtonText: 'Cancel', type: 'warning'
        }).then(() => {
          try {
            // Make a POST request using Axios
            const response = axios.post(
              this.apiUrl+'/orders?action=deleteOrderItem', row,
              { headers: { 'Content-Type': 'multipart/form-data' } }
            );

            // this.loadOrders();

            // this.cartItems = response.data.rows;
            this.cartItems.splice(index, 1);
            this.orderItems = this.cartItems

            this.$notify({
              component: NotificationTemplate,
              icon: 'nc-icon nc-app',
              horizontalAlign: 'center',
              verticalAlign: 'bottom',
              type: 'success',
              message: 'ลบรายการเรียบร้อยแล้ว'
            })
          } catch (error) {
            this.$notify({
              component: NotificationTemplate,
              icon: 'nc-icon nc-app',
              horizontalAlign: 'center',
              verticalAlign: 'bottom',
              type: 'danger',
              message: 'Error !'
            })
          }
        }).catch(() => {
          
        });
      },
      copyOrder () {
        // console.log(this.orderDetail)
        MessageBox.confirm('Copy this Order?', 'Warning', {
          confirmButtonText: 'OK', cancelButtonText: 'Cancel', type: 'warning'
        }).then(() => {
          try {
            // Make a POST request using Axios
            axios.post(
              this.apiUrl+'/orders?action=copyOrder',
              { 'customer': this.orderDetail },
              { headers: { 'Content-Type': 'multipart/form-data' } }
            )
            .then(response => {
              if(response.data.success==true){
                this.listMode = true
                this.isNewOrder = false;

                this.loadOrders();

                this.$notify({
                  component: NotificationTemplate,
                  icon: 'nc-icon nc-app',
                  horizontalAlign: 'center',
                  verticalAlign: 'bottom',
                  type: 'success',
                  message: 'Success'
                })
              }else{
                this.$notify({
                  component: NotificationTemplate,
                  icon: 'nc-icon nc-app',
                  horizontalAlign: 'center',
                  verticalAlign: 'bottom',
                  type: 'danger',
                  message: response.data.errorMsg
                })
              }
            });
            
          } catch (error) {
            this.$notify({
              component: NotificationTemplate,
              icon: 'nc-icon nc-app',
              horizontalAlign: 'center',
              verticalAlign: 'bottom',
              type: 'danger',
              message: 'Error !'
            })
          }
        }).catch(() => {
          
        });
      },
      slipZoomIn (item){
        console.log(item);
        // this.dialogVisible = true
        // this.imageSlip = item.imageUrl
        window.open(item.imageUrl,'_blank')

      },
      async updatePayinInformation () {
        const formData = new FormData();

        let queryParams = {
          'customer' : this.orderDetail,
          'items' : this.cartItems,
        }

        await axios.post(
          this.apiUrl+'/orders?action=updatePayinInformation', 
          formData,
          {
            params: queryParams,
            headers: { 'Content-Type': 'multipart/form-data' }
          }

        )
        .then(response => {
          this.loadOrders();

          this.$notify({
            component: NotificationTemplate,
            icon: 'nc-icon nc-app',
            horizontalAlign: 'center',
            verticalAlign: 'bottom',
            type: 'success',
            message: 'บันทึกการชำระเงินเรียบร้อยแล้ว'
          })
        })
        .catch(error => {
          // this.error = error;

          this.$notify({
            component: NotificationTemplate,
            icon: 'nc-icon nc-app',
            horizontalAlign: 'center',
            verticalAlign: 'bottom',
            type: 'success',
            message: 'พบปัญหาบางประการ ไม่สามารถบันทึกการชำระเงินได้'
          })
        });

        this.listMode = true;
      },
      editProductDate (index, row) {
        this.dialogFormVisible = true

        this.newDate.productID = row.productID
        this.newDate.startDate = this.formatDate(row.startDate)
        this.newDate.endDate = this.formatDate(row.endDate)
      },
      saveProductDate () {
        this.dialogFormVisible = false

        MessageBox.confirm('Are you sure ?', 'Warning', {
          confirmButtonText: 'OK', cancelButtonText: 'Cancel', type: 'warning'
        }).then(() => {
          try {
            // Make a POST request using Axios
            axios.post(
              this.apiUrl+'/products?action=updateProduct&id='+this.newDate.productID,
              { 
                'startDate': this.newDate.startDate,
                'endDate': this.newDate.endDate,
              },
              { headers: { 'Content-Type': 'multipart/form-data' } }
            )
            .then(response => {
              if(response.data.success==true){
                // this.listMode = true
                // this.isNewOrder = false;

                // console.log(this.curentOrderRow);
                this.loadOrderDetail(this.curentOrderRow)


                this.$notify({
                  component: NotificationTemplate,
                  icon: 'nc-icon nc-app',
                  horizontalAlign: 'center',
                  verticalAlign: 'bottom',
                  type: 'success',
                  message: 'Success'
                })
              }else{
                this.$notify({
                  component: NotificationTemplate,
                  icon: 'nc-icon nc-app',
                  horizontalAlign: 'center',
                  verticalAlign: 'bottom',
                  type: 'danger',
                  message: response.data.errorMsg
                })
              }
            });
            
          } catch (error) {
            this.$notify({
              component: NotificationTemplate,
              icon: 'nc-icon nc-app',
              horizontalAlign: 'center',
              verticalAlign: 'bottom',
              type: 'danger',
              message: 'Error !'
            })
          }
        }).catch(() => {
          
        });
      },
      
    }
  }
</script>

<style lang="scss">
  .el-table .td-actions{
    button.btn {
      margin-right: 5px;
    }
  }
  .blink {
      animation: blinker 1.5s linear infinite;
      color: red;
      font-family: sans-serif;
  }
  @keyframes blinker {
    50% { opacity: 0; }
  }
  .showPayinSlipImage { text-align:center;}
  .btnZoomIn { cursor: pointer; }
</style>
