<template>
  <div>

    <!--Stats cards-->
    <div class="row">
      <div class="col-lg-4" v-for="stats in statsCards">
        <stats-card :type="stats.type" :icon="stats.icon" :small-title="stats.title" :title="stats.value">
          <!-- <div class="stats" slot="footer">
            <i :class="stats.footerIcon"></i>
            {{stats.footerText}}
          </div> -->
        </stats-card>
      </div>
    </div>

    <!-- Orders -->
    <div class="card">
      <div class="card-header"><h5 class="card-title">Product Summary </h5></div>
      <div class="card-body row">
        <div class="col-sm-12">
          <el-table :data="products" header-row-class-name="text-primary">
            <!-- <el-table-column type="index"></el-table-column> -->
            <el-table-column prop="id" label="ID" width="80"></el-table-column>
            <el-table-column width="100">
              <template slot-scope="props">
                <div class="img-container"><img :src="props.row.imageUrl" alt=""></div>
              </template>
            </el-table-column>
            <el-table-column prop="name" label="Name"></el-table-column>
            <el-table-column prop="brand" label="Brand"></el-table-column>
            <el-table-column prop="qtyOrder" label="Ordered"></el-table-column>
          </el-table>
          <br />
          <div class="block">
            <el-pagination
              @size-change="tb_product_handleSizeChange"
              @current-change="tb_product_handleCurrentChange"
              :current-page.sync="tb_product.pagination.currentPage"
              :page-sizes="tb_product.pagination.pageSizes"
              :page-size="tb_product.pagination.pageSize"
              :total="tb_product.pagination.total"
              layout="total, sizes, prev, pager, next, jumper"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="row">
      <div class="col-lg-4 col-sm-6">
        <chart-card :chart-data="activeUsersChart.data" chart-id="activity-chart" chart-title="TOTAL EARNINGS IN LAST TEN QUARTERS">
          <span slot="title">$34,657</span>
          <badge slot="title-label" type="success">+18%</badge>
          <template slot="footer-title">Financial Statistics</template>
          <p-button slot="footer-right" aria-label="add button" type="success" round icon size="sm">
            <i class="nc-icon nc-simple-add"></i>
          </p-button>
        </chart-card>
      </div>

      <div class="col-lg-4 col-sm-6">
        <chart-card :chart-data="emailsCampaignChart.data" chart-id="emails-chart" color="#ef8156" chart-title="TOTAL SUBSCRIPTIONS IN LAST 7 DAYS">
          <span slot="title">169</span>
          <badge slot="title-label" type="danger">-14%</badge>
          <template slot="footer-title">View all members</template>
          <p-button slot="footer-right" aria-label="view button" type="danger" round icon size="sm">
            <i class="nc-icon nc-button-play"></i>
          </p-button>
        </chart-card>
      </div>

      <div class="col-lg-4 col-sm-6">
        <chart-card :chart-data="activeCountriesChart.data" chart-id="active-countries-chart" color="#fbc658" chart-title="Active Countries">
          <span slot="title">8,960</span>
          <badge slot="title-label" type="warning">~51%</badge>
          <template slot="footer-title">View more details</template>
          <p-button slot="footer-right" aria-label="view more button" type="warning" round icon size="sm">
            <i class="nc-icon nc-alert-circle-i"></i>
          </p-button>
        </chart-card>
      </div>
    </div> -->

    <div class="row">
      <!-- <div class="col-md-6">
        <task-list></task-list>
      </div> -->
      <div class="col-md-12">
        <chart-card :chart-data="activityChart.data" :chart-height="100" chart-id="activity-bar-chart" chart-type="Bar">
          <template slot="header">
            <h4 class="card-title">2024 Sales</h4>
            <!-- <p class="card-category">All products including Taxes</p> -->
          </template>
          <template slot="footer">
            <div class="legend">
              <i class="fa fa-circle text-warning"></i> Ordered
              <i class="fa fa-circle text-info"></i> Paid
            </div>
            <!-- <hr> -->
            <!-- <div class="stats">
              <i class="fa fa-check"></i> Data information certified
            </div> -->
          </template>
        </chart-card>
      </div>
    </div>

    <!-- <div class="row">
      <div class="col-lg-3 col-sm-6">
        <circle-chart-card :percentage="70" chart-id="email-statistics-chart" title="Email Statistics" description="Last Campaign Performance" color="#4acccd">
          <template slot="footer">
            <div class="legend"><i class="fa fa-circle text-info"></i> Open</div>
            <hr>
            <div class="stats"><i class="fa fa-calendar"></i> Number of emails sent</div>
          </template>
        </circle-chart-card>
      </div>

      <div class="col-lg-3 col-sm-6">
        <circle-chart-card :percentage="34" chart-id="new-visitors-chart" title="New Visitators" description="Out Of Total Number" color="#fcc468">
          <template slot="footer">
            <div class="legend"><i class="fa fa-circle text-warning"></i> Visited</div>
            <hr>
            <div class="stats"><i class="fa fa-check"></i> Campaign sent 2 days ago</div>
          </template>
        </circle-chart-card>
      </div>

      <div class="col-lg-3 col-sm-6">
        <circle-chart-card :percentage="80" title="Orders" chart-id="orders-chart" description="Total Number" color="#f17e5d">
          <template slot="footer">
            <div class="legend"><i class="fa fa-circle text-danger"></i> Completed</div>
            <hr>
            <div class="stats"><i class="fa fa-clock-o"></i> Updated 3 minutes ago</div>
          </template>
        </circle-chart-card>
      </div>

      <div class="col-lg-3 col-sm-6">
        <circle-chart-card :percentage="11" title="Subscriptions" description="Our Users" color="#66615b">
          <template slot="footer">
            <div class="legend"><i class="fa fa-circle text-secondary"></i> Ended</div>
            <hr>
            <div class="stats"><i class="fa fa-history"></i> Total users</div>
          </template>
        </circle-chart-card>
      </div>
    </div> -->

  </div>
</template>
<script>
  import Vue from 'vue'
  import CircleChartCard from 'src/components/UIComponents/Cards/CircleChartCard.vue'
  import ChartCard from 'src/components/UIComponents/Cards/ChartCard';
  import StatsCard from 'src/components/UIComponents/Cards/StatsCard';
  import {Badge} from 'src/components/UIComponents'
  import Loading from 'src/components/Dashboard/Layout/LoadingMainPanel.vue'
  import TaskList from './Widgets/TaskList'
  import axios from 'axios'

  import {Table, TableColumn} from 'element-ui'
  import {Input, Button, Select, Option, Autocomplete, Pagination} from 'element-ui'

  const WorldMap = () => ({
    component: import('./../Maps/WorldMap.vue'),
    loading: Loading,
    delay: 200
  })

  Vue.use(Table)
  Vue.use(TableColumn)

  export default {
    components: {
      StatsCard,
      ChartCard,
      CircleChartCard,
      WorldMap,
      Badge,
      TaskList,
      [Pagination.name]: Pagination,
    },
    /**
     * Chart data used to render stats, charts. Should be replaced with server data
     */
    data () {
      return {
        statsCards: [
          {
            type: 'warning',
            icon: 'nc-icon nc-money-coins',
            title: 'Ordered',
            value: '',
            // footerText: 'Updated now',
            // footerIcon: 'nc-icon nc-refresh-69'
          },
          {
            type: 'info',
            icon: 'nc-icon nc-money-coins',
            title: 'Confirmed',
            value: '',
            // footerText: 'Last day',
            // footerIcon: 'nc-icon nc-calendar-60'
          },
          {
            type: 'danger',
            icon: 'nc-icon nc-money-coins',
            title: 'Cancel',
            value: '',
            // footerText: 'Updated now',
            // footerIcon: 'nc-icon nc-refresh-69'
          },
          {
            type: 'success',
            icon: 'nc-icon nc-money-coins',
            title: 'Complete',
            value: '',
            // footerText: 'In the last hour',
            // footerIcon: 'nc-icon nc-bell-55'
          },
          {
            type: 'success',
            icon: 'nc-icon nc-money-coins',
            title: 'Balance',
            value: '',
            // footerText: 'In the last hour',
            // footerIcon: 'nc-icon nc-bell-55'
          },
        ],
        activeUsersChart: {
          data: {
            labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct"],
            series: [542, 480, 430, 550, 530, 453, 380, 434, 568, 610]
          }
        },
        emailsCampaignChart: {
          data: {
            labels: ["12pm", "3pm", "6pm", "9pm", "12am", "3am", "6am", "9am"],
            series: [40, 500, 650, 700, 1200, 1250, 1300, 1900]
          }
        },
        activeCountriesChart: {
          data: {
            labels: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October"],
            series: [80, 78, 86, 96, 83, 85, 76, 75, 88, 90]
          }
        },
        activityChart: {
          data: {}
        },
        products: [],
        tb_product: {
          pagination: {
            pageSizes: [5, 10, 20, 50],
            pageSize: 5,
            total: 0,
            currentPage: 1,
          },
        },
        apiUrl: process.env.NODE_ENV === 'production'?'https://www.hbrxws.com/api':'http://localhost/hbrxws/api',
      }
    },
    mounted () {
      this.checkAuth();
      this.loadStatsCards();
      this.loadItems()
      this.loadSalesStatistic();
    },
    methods:{
      async checkAuth(){
        const token = localStorage.getItem('authToken');

        // axios.defaults.withCredentials = true;
        await axios.post(
          this.apiUrl+'/user?action=getUserDetail', { token:token },
          { headers: { 'Content-Type': 'multipart/form-data' } }
        )
        .then(response => {
          if(response.data.role!='admin'){
            this.$router.push('/login');
          }
        });
      },
      tb_product_handleSizeChange(val) {
        this.tb_product.pagination.pageSize = val;
        this.loadItems();
      },
      tb_product_handleCurrentChange(val) {
        this.tb_product.pagination.currentPage = val;
        this.loadItems();
      },
      async loadItems () {
        axios.post(
          this.apiUrl+'/products',
          { 
            'action': 'productOrdered',
            'pageSize': this.tb_product.pagination.pageSize,
            'currentPage': this.tb_product.pagination.currentPage,
            'q': this.textSearch,
          },
          {
            headers: { 'Content-Type': 'multipart/form-data' }
          }
        )
        .then(response => {
          this.products = response.data.rows;
          this.tb_product.pagination.total = parseInt(response.data.total);
        })
        .catch(error => {
          this.error = error;
        });

      },
      async loadStatsCards (){
        axios.post(
          this.apiUrl+'/orders',{ 'action': 'loadStatsCards',},
          { headers: { 'Content-Type': 'multipart/form-data' } }
        )
        .then(response => {
          this.statsCards[0]['value'] = response.data.ordered;
          this.statsCards[1]['value'] = response.data.confirmed;
          this.statsCards[2]['value'] = response.data.canceled;
          this.statsCards[3]['value'] = response.data.complete;
          this.statsCards[4]['value'] = response.data.balance;
        })
        .catch(error => {
          this.statsCards[0]['value'] = '-';
          this.statsCards[1]['value'] = '-';
          this.statsCards[2]['value'] = '-';
          this.statsCards[3]['value'] = '-';
          this.statsCards[4]['value'] = '-';
        });       
      },
      async loadSalesStatistic(){
        let data_1 = []
        let data_2 = []

        let res = await axios.post(
          this.apiUrl+'/orders',{ 'action': 'loadSalesStatistic',},
          { headers: { 'Content-Type': 'multipart/form-data' } }
        )
        .then(response => {
          return response;
        })
        .catch(error => {
          // console.log(error)
        });

        res.data.forEach(row => {
          data_1.push(parseInt(row.totalOrdered));
          data_2.push(parseInt(row.totalComplete));
        });

        this.activityChart.data = {
          labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
          datasets: [
            {
              label: "Data",
              borderColor: '#fcc468',
              fill: true,
              backgroundColor: '#fcc468',
              hoverBorderColor: '#fcc468',
              borderWidth: 8,
              data: data_1,
            },
            {
              label: "Data",
              borderColor: '#4cbdd7',
              fill: true,
              backgroundColor: '#4cbdd7',
              hoverBorderColor: '#4cbdd7',
              borderWidth: 8,
              data: data_2,
            }
          ]
        }

      },
    }
  }

</script>
<style>

</style>
